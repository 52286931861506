<template>
  <div class="col-12 m-0 p-0 bordered">
    <div class="col-12 mb-1 p-0 text-center">
      {{`Child ${index+1}`}}
    </div>
    <div class="col-12 mb-1 p-0">
      Name
    </div>
    <div class="col-12 m-0 p-0 pb-4">
      <form-element :disabled="disabled" :background="background" :inputData="child.name" type="text" :optional="false" @updated="child.name = $event">
      </form-element>
    </div>
    <div class="col-12 mb-1 p-0">
      Surname
    </div>
    <div class="col-12 m-0 p-0 pb-4">
      <form-element :disabled="disabled" :background="background" :inputData="child.surname" type="text" :optional="false" @updated="child.surname = $event">
      </form-element>
    </div>
    <div class="col-12 mb-1 p-0">
      Date of Birth
    </div>
    <div class="col-12 m-0 p-0 pb-4">
      <form-element :disabled="disabled" :background="background" :inputData="child.dob" type="date" :optional="false" @updated="child.dob = $event">
      </form-element>
    </div>
    <div class="col-12 mb-1 p-0">
      Any Dietary Requirements or Allergies
    </div>
    <div class="col-12 m-0 p-0 pb-4">
      <form-element :disabled="disabled" :background="background" :inputData="child.dietaryRequirementsAllergies" type="textarea" :optional="true" @updated="child.dietaryRequirementsAllergies = $event">
      </form-element>
    </div>
    <div class="col-12 mb-1 p-0">
      Favourite Delights
    </div>
    <div class="col-12 m-0 p-0 pb-4">
      <form-element :disabled="disabled" :background="background" :inputData="child.favouriteDelights" type="textarea" :optional="true" @updated="child.favouriteDelights = $event">
      </form-element>
    </div>
    <div class="col-12 mb-1 p-0">
      Favourite Animal
    </div>
    <div class="col-12 m-0 p-0 pb-4">
      <form-element :disabled="disabled" :background="background" :inputData="child.favouriteAnimal" type="textarea" :optional="true" @updated="child.favouriteAnimal = $event">
      </form-element>
    </div>
  </div>
</template>
<script>
const FormElement = () => import('@/components/FormElement')
export default {
  name: 'ChildGuestQuestionnaire',
  props: {
    background: {
      value: String,
      default: null
    },
    child: {
      value: Object,
      required: true
    },
    disabled: {
      value: Boolean,
      default: false
    },
    index: {
      value: Number,
      required: true
    }
  },
  components: {
    FormElement
  }
  // methods: {
  //   changeAnswer (field, value) {
  //     if (typeof value !== 'undefined') {
  //       this[field] = value
  //     }
  //     this.$emit('updated', {
  //       index: this.index,
  //       details: {
  //         name: this.name,
  //         surname: this.surname,
  //         email: this.email
  //       }
  //     })
  //   }
  // }
}
</script>
<style scoped>
.bordered {
  border: 1px dotted;
  border-color: inherit;
  padding: 3px !important;
  margin-bottom: 5px !important;
}
</style>
